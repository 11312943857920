import * as React from "react";

import { cn } from "@/lib/utils";
import { useSortable } from "@dnd-kit/sortable";
import { Icon } from "@/components/Icon";
import { CSSProperties } from "react";
import { CSS } from "@dnd-kit/utilities";
import { Link } from "@inertiajs/react";
import { TODO } from "@/types";

const Table = React.forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement>
>(({ className, ...props }, ref) => (
  <table
    ref={ref}
    className={cn("w-full caption-bottom text-sm", className)}
    {...props}
  />
));
Table.displayName = "Table";

const TableHeader = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <thead
    ref={ref}
    className={cn(
      "top-0 sticky bg-muted/50 backdrop-blur-sm text-table-header-default-text shadow z-10 rounded-[inherit]",
      className,
    )}
    {...props}
  />
));
TableHeader.displayName = "TableHeader";

const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "table-row-group bg-table-body-default-bg text-table-body-default-text",
      className,
    )}
    {...props}
  />
));
TableBody.displayName = "TableBody";

const TableFooter = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tfoot ref={ref} className={className} {...props} />
));
TableFooter.displayName = "TableFooter";

type BaseProps = {
  className?: string;
};

type TableRowLinkProps = React.ComponentProps<typeof Link>;
type TableRowDivProps = React.HTMLAttributes<HTMLDivElement>;

// More specific discriminated union type
type Props =
  | (BaseProps &
      ({
        asLink: true;
        ref?: React.ComponentRef<typeof Link>;
      } & TableRowLinkProps))
  | ({
      asLink?: false;
      ref?: React.RefObject<HTMLDivElement>;
    } & TableRowDivProps);

const TableRow = React.forwardRef<HTMLDivElement | typeof Link, Props>(
  ({ className, asLink, ...props }, ref) => {
    const Comp = asLink ? Link : "div";

    return (
      <Comp
        ref={ref as TODO}
        data-link={asLink}
        className={cn(
          "table-row bg-table-body-default-bg border-b border-table-body-default-border",
          "hover:bg-zinc-100 dark:hover:bg-zinc-900",
          className,
        )}
        {...(props as TODO)}
      />
    );
  },
);

TableRow.displayName = "TableRow";

export const SortableTableRow = (
  props: React.HTMLAttributes<HTMLDivElement> & { rowKey: string },
) => {
  const { rowKey, ...otherProps } = props;
  const { transform, transition, setNodeRef, isDragging } = useSortable({
    id: props.rowKey,
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform), //let dnd-kit do its thing
    transition: transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
  };

  return (
    <TableRow
      {...otherProps}
      ref={setNodeRef as React.Ref<HTMLDivElement>}
      style={style}
    />
  );
};
SortableTableRow.displayName = "SortableTableRow";

export const RowDragHandleCell = ({ rowId }: { rowId: string }) => {
  const { attributes, listeners, setNodeRef } = useSortable({
    id: rowId,
  });

  return (
    // Alternatively, you could set these attributes on the rows themselves
    // set after:bg-gray-800/20 to see hit area
    <button
      ref={setNodeRef}
      key={rowId}
      {...attributes}
      {...listeners}
      className="relative group after:absolute after:inset-[-12px] after:content-['']"
    >
      <Icon
        icon={"fa-solid fa-grip-vertical"}
        className="text-muted-foreground opacity-0 transition-opacity duration-75 group-hover:opacity-100"
      />
    </button>
  );
};

const TableHeadRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement>
>(({ ...props }, ref) => <tr ref={ref} {...props} />);
TableHeadRow.displayName = "TableHeadRow";

const TableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={cn(
      "cursor-pointer hover:bg-table-header-hover-bg",
      "h-11 px-2 text-left align-middle whitespace-nowrap font-medium [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]",
      className,
    )}
    {...props}
  />
));
TableHead.displayName = "TableHead";

const TableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={cn(
      "p-2 align-middle [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]",
      className,
    )}
    {...props}
  />
));
TableCell.displayName = "TableCell";

const TableCaption = React.forwardRef<
  HTMLTableCaptionElement,
  React.HTMLAttributes<HTMLTableCaptionElement>
>(({ className, ...props }, ref) => (
  <caption
    ref={ref}
    className={cn("mt-4 text-sm text-muted-foreground", className)}
    {...props}
  />
));
TableCaption.displayName = "TableCaption";

export {
  Table,
  TableHeader,
  TableHeadRow,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  TableCaption,
};
